import React from 'react'
import { SpinnerRoundFilled } from 'spinners-react';

export const Spinner = ({ isVisible }) => {
  if( !isVisible) return null;

  return (
    <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center'>
      <div className=''>
        <SpinnerRoundFilled />
      </div>      
    </div>
  )
}